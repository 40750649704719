var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/diff.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),{"name":"diff","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("/home/travis/build/thelounge/thelounge/client/views/user_name.tpl"),depth0,{"name":"user_name","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,(data && data.first),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	<div class=\"user-mode "
    + container.escapeExpression(__default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/modes.js")).call(alias1,(depth0 != null ? depth0.mode : depth0),{"name":"modes","hash":{},"data":data}))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "		</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/diff.js")).call(alias1,"reset",{"name":"diff","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.users : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});